<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.Evaluation">
        <h1 class="title">
          {{ $t("evaluation.title") }}
        </h1>
      </page-header>
      <div class="bg-white">
        <div class="" style="background: #019fc4">
          <div class="filters" style="margin: 30px 0px">
            <div class="filter-item">
              <many2one-field
                label="Société"
                :fetch="
                  $api.fetchCompaniesForEvaluation.bind(this, agenciesIds)
                "
                objectName="companies"
                v-model="companies"
                reference="name"
                :columns="companiesColumns"
                :hasFilter="true"
                :inline="false"
                :edit="true"
              ></many2one-field>
            </div>
            <div class="filter-item">
              <many2one-field
                label="Établissement"
                :fetch="
                  $api.fetchAgenciesForEvaluation.bind(this, companiesIds)
                "
                v-model="agencies"
                reference="edifice"
                :columns="agencyColumns"
                :hasFilter="true"
                :inline="false"
                :edit="true"
              ></many2one-field>
            </div>
            <div class="filter-item">
              <div class="input-label-eval">Note moyenne</div>
              <div class="average-input-container">
                <text-field
                  type="number"
                  v-model.number="min"
                  :inline="false"
                  :edit="true"
                />
                <text-field
                  type="number"
                  v-model.number="max"
                  :inline="false"
                  :edit="true"
                />
              </div>
            </div>
            <div class="filter-item">
              <div class="input-label-eval">Date de l'évaluation</div>
              <div class="date-field-container">
                <date-field
                  class="date-field-eval"
                  v-model="start_date"
                  :inline="false"
                  :edit="true"
                ></date-field>
                <date-field
                  class="date-field-eval"
                  v-model="end_date"
                  :inline="false"
                  :edit="true"
                ></date-field>
              </div>
            </div>
            <div class="text-center m-button">
              <button class="button is-warning" @click="HandleFiltering()">
                Rechercher
              </button>
            </div>
          </div>
        </div>
        <div>
          <div class="columns" style="margin: 0px">
            <div class="column is-half" style="min-width: 600px">
              <div class="border" style="padding: 10px">
                <h2 class="is-size-6">Liste des sociétés</h2>
                <div class="data-table-container">
                  <evaluation-table
                    ref="evaluationTable"
                    :fetch="$api.fetchCompaniesEvaluations"
                    :paginateOptions="[10, 20, 50]"
                    :paramsFilters="params"
                  />
                </div>
              </div>
            </div>

            <div
              class="columns"
              style="width: 100%; margin: 0px; min-width: 600px"
            >
              <div class="column">
                <div class="border">
                  <h2 class="is-size-6">Nombre d'évaluations</h2>
                  <div class="eval-number-box">{{ totalEvaluations }}</div>
                </div>
                <div class="border">
                  <h2 class="is-size-6">Note moyenne</h2>
                  <div style="height: 350px; align-content: center">
                    <div class="chart-container">
                      <div class="overlay-circle"></div>
                      <gauge-chart
                        :value="averageScore"
                        :minValue="-3.3"
                        :maxValue="10"
                        class="gauge-chart"
                      />
                      <div class="value-display">{{ averageScore }}</div>
                      <div class="min-value">0.0</div>
                      <div class="max-value">10.0</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="border">
                  <h2 class="is-size-6">Nombre d'établissements</h2>
                  <!-- <div class="eval-number-box">{{ totalProjects }}</div>  -->
                </div>
                <div class="border">
                  <h2 class="is-size-6">Radar des notes</h2>
                  <div class="chart-radar-container">
                    <radar-chart
                      :chartData="chartData"
                      :options="chartOptions"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "evaluation",

  data() {
    return {
      companies: [],
      agencies: [],
      min: 0,
      max: 10,
      start_date: "2000-01-01",
      end_date: new Date().toISOString().slice(0, 10),
      summaryData: {},
      totalEvaluations: 0,
      totalProjects: 0,
      averageScore: 0,
      companiesLength: 0,
      radarNotations: [0, 0, 0, 0, 0],

      companiesColumns: {
        name: this.$t("common.name"),
        edifice: this.$t("common.edifice"),
        siren: this.$t("common.siren"),
        manager: this.$t("common.manager"),
        mail: this.$t("common.mail"),
      },
      agencyColumns: {
        edifice: this.$t("common.agency"),
        siret: this.$t("common.siret"),
        manager: this.$t("common.manager"),
        "company.name": this.$t("common.company"),
      },
      chartData: {
        labels: [
          "Qualité",
          "Technique",
          "Délais et Réactivité",
          "Sécurité",
          "Relationnel",
        ],
        datasets: [
          {
            label: "Valeurs",
            backgroundColor: "rgba(159, 209, 255, 0.5)",
            borderColor: "rgba(159, 209, 255, 0.5)",
            pointBackgroundColor: "#118DF8",
            pointHoverBackgroundColor: "#fff",
            pointBorderWidth: 5,
            pointBorderColor: "#108CFC",
            data: this.radarNotations,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          r: {
            max: 5,
            min: 0,
            ticks: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            position: "bottom",
            align: "end",
            labels: {
              boxWidth: 12,
              padding: 10,
            },
          },
        },
      },
    };
  },

  computed: {
    companiesIds() {
      if (this.companies.length === 0) return [];

      return this.companies.reduce((acc, val) => {
        return [...acc, val.id];
      }, []);
    },
    agenciesIds() {
      if (this.agencies.length === 0) return [];

      return this.agencies.reduce((acc, val) => {
        return [...acc, val.id];
      }, []);
    },

    params() {
      return (
        "start_date=" +
        this.start_date +
        "&end_date=" +
        this.end_date +
        "&companies=" +
        this.companiesIds +
        "&agencies=" +
        this.agenciesIds
      );
    },
  },

  watch: {
    // companies(val) {
    //   this.updateAgencies();
    //   if (
    //     this.companiesLength === 0 ||
    //     (val.length !== 0 && this.companiesLength > val.length)
    //   ) {
    //     this.agencies = [];
    //   }
    //   this.companiesLength = val.length;
    // },
  },

  mounted() {
    this.fetchEvaluationSummary();
  },

  methods: {
    HandleFiltering() {
      this.$refs.evaluationTable.refreshWithFilters(this.params);
      this.fetchEvaluationSummary();
    },

    fetchEvaluationSummary() {
      this.$api
        .fetchEvaluationSummary(this.params)
        .then((response) => {
          this.summaryData = response;
          this.averageScore = parseFloat(this.summaryData.score.toFixed(1));
          this.totalProjects = this.summaryData.project.count;
          this.totalEvaluations = this.summaryData.count;
          this.radarNotations = [
            this.summaryData.project.quality.score,
            this.summaryData.project.technic.score,
            this.summaryData.project.reactivity.score,
            this.summaryData.project.safety.score,
            this.summaryData.project.relational.score,
          ];
          this.chartData.datasets[0].data = this.radarNotations;
        })
        .catch((error) => {
          console.error("Error fetching agency evaluations:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.border {
  color: black;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  margin-bottom: 15px;
  h2 {
    padding: 5px;
  }
}
.eval-col-bg {
  background-color: white;
  margin: 10px;
}

.bg-white {
  background-color: white;
}

.eval-number-box {
  height: 80px;
  background: white;
  align-content: space-evenly;
  text-align: center;
  font-size: xxx-large;
}

.filters {
  display: flex;
}

.filter-item {
  flex: 1;
  padding: 5px 10px;
  background-color: white;
  border-radius: 5px;
  margin: 10px;
  transition-duration: 1s;
  transition-property: max-height;
  max-height: 100px;
  overflow-y: auto;
  & > * {
    margin: 0 !important;
  }
}

.m-button {
  margin: auto 10px;
}

.overlay-circle {
  width: 230px;
  height: 230px;
  position: absolute;
  border-radius: 50%;
  top: 10px;
  left: 10px;
  border: solid 8px rgb(1, 159, 196);
  z-index: 1;
}

.chart-container {
  width: 250px;
  height: 250px;
  position: relative;
}

canvas {
  width: 100%;
  height: 100%;
  display: block;
  box-sizing: border-box;
}

.chart-radar-container {
  display: flex;
  justify-content: center;
  background-color: white;
  height: 350px;
}

.input-label-eval {
  font-weight: bold;
  vertical-align: top;
  padding-right: 4px;
}

.average-input-container {
  display: flex;
  width: 100%;
  input {
    margin-right: 10px;
    max-width: 80px;
  }
}
.date-field-container {
  display: flex;
}
.date-field-eval {
  width: 100px !important;
  margin-right: 10px;
  input {
    font-size: 0.8rem;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 0%;
  }
}

.text-center {
  text-align: center;
}

.value-display {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  font-weight: bold;
}
.min-value {
  position: absolute;
  bottom: 67px;
  left: 80px;
  font-size: 12px;
}

.max-value {
  position: absolute;
  bottom: 67px;
  right: 80px;
  font-size: 12px;
}
</style>
