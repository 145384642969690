var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"evaluation-container"},[_c('h2',{staticClass:"is-size-5"},[_vm._v(_vm._s(_vm.$t("evaluation.title")))]),_c('div',[(
          _vm.getRights(_vm.$entitiesName.Evaluation).create &&
          _vm.userRoleOptions.includes(_vm.$roleOptions.EVALUATIONS.EVALUATE_SAFETY)
        )?_c('button',{staticClass:"button is-success is-pulled-right is-small m-l-20",on:{"click":[function($event){$event.stopPropagation();return _vm.$refs.addEvaluationModal.open()},function($event){return _vm.setEvaluationType('safety')}]}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v("  "+_vm._s(_vm.$t("evaluation.safetyTitle"))+" ")]):_vm._e(),(
          _vm.getRights(_vm.$entitiesName.Evaluation).create &&
          _vm.userRoleOptions.includes(
            _vm.$roleOptions.EVALUATIONS.EVALUATE_PROCUREMENT
          )
        )?_c('button',{staticClass:"button is-success is-pulled-right is-small",on:{"click":[function($event){$event.stopPropagation();return _vm.$refs.addEvaluationModal.open()},function($event){return _vm.setEvaluationType('procurement')}]}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v("  "+_vm._s(_vm.$t("evaluation.procurementTitle"))+" ")]):_vm._e()])]),_c('datatable',{key:"agencyEvaluation",ref:"agencyEvaluation",attrs:{"fetch":_vm.fetchEvaluationPerAgency,"objectName":"agencyEvaluation","enableDownload":false,"size":10,"noSelection":true,"isSmall":true,"reversePagination":true,"singleSelect":true,"filterColumns":false,"paginateOptions":[10, 20, 50]},scopedSlots:_vm._u([{key:"type",fn:function(ref){
        var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('evaluation.type'),"sortable":true}},[_vm._v(" "+_vm._s(_vm.getTypeTranslation(item.type))+" ")])}},{key:"value",fn:function(ref){
        var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('evaluation.evaluation'),"sortable":true}},[_vm._v(" "+_vm._s(item.value)+" ")])}},{key:"comment",fn:function(ref){
        var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('evaluation.comment'),"sortable":true}},[_vm._v(" "+_vm._s(item.comment)+" ")])}},{key:"document",fn:function(ref){
        var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('evaluation.document'),"sortable":true}},[_c('div',{staticClass:"document-container"},[(item.documents && item.documents.length > 0)?_c('i',{staticClass:"fa fa-cloud-download",on:{"mouseenter":function($event){return _vm.showPopup(item.id)},"mouseleave":function($event){return _vm.hidePopup(item.id)},"click":function($event){return _vm.downloadAllDocuments(item.documents)}}}):_vm._e(),(_vm.isPopupVisible(item.id))?_c('div',{staticClass:"filename-popup"},[_c('ul',_vm._l((item.documents),function(doc,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(doc.filename)+" ")])}),0)]):_vm._e()])])}}])}),_c('modal',{ref:"addEvaluationModal",attrs:{"title":_vm.$t(("evaluation." + (this.evaluationType) + "Title"))},on:{"create":_vm.addEvaluation}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column",staticStyle:{"height":"650px"}},[_c('star-rating',{attrs:{"label":_vm.$t('evaluation.evaluation'),"number-of-stars":10},on:{"rating-selected":_vm.handleRating}}),_c('textarea-field',{staticClass:"m-t-15",attrs:{"label":_vm.$t('evaluation.comment'),"inline":false,"edit":true},model:{value:(_vm.evaluation.comment),callback:function ($$v) {_vm.$set(_vm.evaluation, "comment", $$v)},expression:"evaluation.comment"}}),_c('div',{staticClass:"multi-upload-container"},[_c('div',{staticClass:"document-label"},[_vm._v("Documents")]),_c('vue-dropzone',{ref:"vueDropzone",attrs:{"options":_vm.dropzoneOptions,"id":"customdropzone","include-styling":false,"useCustomSlot":true},on:{"vdropzone-file-added":_vm.handleFileAdded,"vdropzone-file-removed":_vm.handleFileRemoved}},[(_vm.selectedFiles.length === 0)?_c('div',{staticClass:"default-message"},[_c('i',{staticClass:"fa fa-cloud-upload"}),_vm._v("Déposer ou faire glisser ")]):_vm._e()])],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }